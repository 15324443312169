<template>
	<div class="organization">
		<div class="actions">
			<div class="add" @click="openAddDialog()">
				<div class="el-icon-plus"></div> 添加角色
			</div>
		</div>
		<div class="table_tit">
			<div>序号</div>
			<div>角色名称</div>
			<div>创建时间</div>
			<div>角色人数</div>
			<div>操作</div>
		</div>
		<div v-loading="loading">
			<div class="organization_list" v-if="organization_list.length">
				<div class="item" v-for="(item,index) in organization_list" :key="index">
					<div>{{index+1}}</div>
					<div>{{item.institution_name}}</div>
					<div>{{$util.timeStampTurnTime(item.create_time)}}</div>
					<div>{{item.member_count}}人</div>
					<div class="action">
						<div class="btn" @click="openAddDialog('edit',item)">修改</div>
						<div class="btn" @click="deleteOrganization(item.institution_id)">删除</div>
						<div class="btn" @click="ViewEmployee(item.institution_id)">查看员工</div>
					</div>
				</div>
			</div>
			<div class="empty_text" v-else>暂无角色</div>
			<div class="pager">
				<el-pagination background :pager-count="5" :total="total" prev-text="上一页" next-text="下一页"
					:current-page.sync="currentPage" :page-size.sync="pageSize" @size-change="handlePageSizeChange"
					@current-change="handleCurrentPageChange" hide-on-single-page></el-pagination>
			</div>
		</div>
		<el-dialog :visible.sync="add_dialog" :show-close="false" class="add_dialog">
			<div class="add_content">
				<div class="tit">{{is_add ? '添加角色':'修改角色信息'}}</div>
				<div class="condition">
					<div class="condition_item">
						<div class="left">
							*<div class="name">角色名称：</div>
						</div>
						<div class="right">
							<input type="text" placeholder="请输入角色名称" v-model="organization_name">
						</div>
					</div>
					<div class="condition_item">
						<div class="left">
							*<div class="name">角色权限：</div>
						</div>
						<div class="right">
							<el-tree ref="authorityTree" :data="all_authority" show-checkbox node-key="key" default-expand-all  :props="{children: 'child_list',label: 'name'}">
							</el-tree>
						</div>
					</div>
				</div>
				<div class="btns">
					<div class="btn save" @click="addorganization()">保存</div>
					<div class="btn" @click="add_dialog = false">返回</div>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import { OrganizationPageList, deleteOrganization, authorityTree, addOrganization, editOrganization } from "@/api/organization"
	export default {
		data() {
			return {
				organization_list: [],
				total: 0,
				currentPage: 1,
				pageSize: 6,
				loading: true,
				add_dialog: false,
				organization_name: '',
				all_authority:[],
				is_add:true,
				modify_organization_id:''
			}
		},
		created() {
			this.getOrganizationList();
			this.getAuthorityTree();
		},
		methods: {
			ViewEmployee(id){
				this.$router.push('/member/employee_management?institution_id='+id)
			},
			addorganization(){
				if(!this.organization_name){
					this.$message.error('角色名称不能为空');
					return;
				}
				if(!this.$refs.authorityTree.getCheckedKeys().length){
					this.$message.error('角色权限不能为空');
					return;
				}
				if(this.is_add){
					addOrganization({
						institution_name:this.organization_name,
						authority:this.$refs.authorityTree.getCheckedKeys().toString()
					})
					.then(res=>{
						if(res.code>=0){
							this.$message({
								message: '添加成功'
							})
							this.add_dialog = false;
							this.getOrganizationList();
						}else {
							this.$message.error(res.message)
						}
					})
					.catch(err=>{
						this.$message.error(err.message)
					})
				}else{
					//修改
					editOrganization({
						institution_name:this.organization_name,
						institution_id:this.modify_organization_id,
						authority:this.$refs.authorityTree.getCheckedKeys().toString()
					})
					.then(res=>{
						if(res.code>=0){
							this.$message({
								message: '修改成功'
							})
							this.add_dialog = false;
							this.getOrganizationList();
						}else{
							this.$message.error(res.message)
						}
					})
					.catch(err=>{
						this.$message.error(err.message)
					})
				}
				
			},
			getAuthorityTree(){
				authorityTree()
				.then(res=>{
					if(res.code>=0){
						this.all_authority = res.data;
					}
				})
			},
			openAddDialog(mode='',organization='') {
				this.add_dialog = true;
				this.organization_name = '';
				this.modify_organization_id = '';
				this.is_add = true;
				this.$nextTick(()=>{
					this.$refs.authorityTree.setCheckedKeys([]);
				})
				if(mode=='edit'){
					this.is_add = false;
					this.modify_organization_id = organization.institution_id;
					this.organization_name = organization.institution_name;
					this.$nextTick(()=>{
						this.$refs.authorityTree.setCheckedKeys(organization.authority.split(','))
					})
				}
			},
			deleteOrganization(id) {
				this.$confirm("您确定要删除该角色吗？", "提示", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "warning"
				}).then(() => {
					deleteOrganization({
						institution_id: id
					})
					.then(res => {
						if (res.code >= 0) {
							this.$message({
								message: '删除成功'
							})
							this.getOrganizationList();
						} else {
							this.$message.error(res.message)
						}
					})
					.catch(err=>{
						this.$message.error(err.message)
					})
				})
			},
			handleCurrentPageChange(page) {
				this.currentPage = page
				this.getDepartmentList();
			},
			handlePageSizeChange(size) {
				this.pageSize = size
				this.getDepartmentList();
			},
			getOrganizationList() {
				this.loading = true;
				OrganizationPageList({
						page: this.currentPage,
						page_size: this.pageSize
					})
					.then(res => {
						if (res.code >= 0) {
							this.organization_list = res.data.list;
							this.total = res.data.count;
							this.loading = false;
						}
					})
					.catch(err => {

					})
			}
		},
	}
</script>

<style lang="scss">
	.empty_text{
		text-align: center;
		padding: 10px;
	}
	.organization {
		min-height: 730px;
		box-sizing: border-box;
		background-color: #fff;
		padding: 20px 20px 20px 18px;

		.add_dialog {
			.el-dialog__body {
				padding: 0;

				.add_content {
					padding: 8px 44px 41px;

					.tit {
						color: #111;
						font-size: 16px;
						line-height: 22px;
						font-weight: 600;
						margin-bottom: 24px;
					}

					.condition {
						padding-left: 33px;
						.condition_item {
							display: flex;
							align-items: baseline;
							margin-bottom: 20px;
							.left {
								display: flex;
								align-items: center;
								color: #E02020;
								font-size: 12px;
								line-height: 16px;
								min-width: 70px;
								margin-right: 2px;
								justify-content: flex-end;
								.name {
									margin-left: 5px;
									color: #222;
									font-size: 12px;
									line-height: 16px;
								}
							}
							.right {
								flex: 1;
								input {
									width: 45%;
									outline: none;
									border: 1px solid #DBDCDC;
									padding: 8px 12px;
									font-size: 12px;
									line-height: 16px;
									color: #333;
									&::placeholder {
										color: #999;
									}
								}
								.el-tree{
									max-height: 430px;
									overflow: auto;
								}
							}
						}
					}

					.btns {
						margin-top: 12px;
						display: flex;
						align-items: center;
						padding-left: 104px;

						.btn {
							cursor: pointer;
							margin-right: 24px;
							color: #333;
							font-size: 14px;
							line-height: 16px;
							padding: 9px 20px;
							border-radius: 2px;
							border: 1px solid #DBDCDC;

							&.save {
								background-color: #007AFF;
								color: #fff;
							}
						}
					}
				}
			}
		}
		.actions {
			display: flex;
			align-items: center;
			margin-bottom: 16px;
			.add{
				cursor: pointer;
				color: #fff;
				font-size: 13px;
				line-height: 18px;
				padding: 6px 13px;
				border-radius: 4px;
				display: flex;
				align-items: center;
				background-color: #00A98F;
				.el-icon-plus{
					font-size: 12px;
					margin-right: 3px;
					line-height: 12px;
				}
			}
		}
		.table_tit {
			background-color: #F7F8FA;
			border-radius: 4px;
			display: flex;
			align-items: center;
			padding: 16px 17px;
			div {
				color: #222;
				font-size: 12px;
				line-height: 16px;

				&:first-child {
					width: 10%;
				}

				&:nth-child(2) {
					width: 20%;
				}

				&:nth-child(3) {
					width: 23%;
				}

				&:nth-child(4) {
					// width: 20%;
					flex: 1;
				}

				&:nth-child(5) {
					width: 18%;
				}
			}
		}

		.organization_list {
			padding: 20px 17px 0;

			.item {
				display: flex;
				align-items: center;
				margin-bottom: 32px;

				>div {
					color: #55555D;
					font-size: 12px;
					line-height: 16px;

					&:first-child {
						width: 10%;
					}

					&:nth-child(2) {
						width: 20%;
					}

					&:nth-child(3) {
						width: 23%;
						font-family: auto;
					}
					&:nth-child(4) {
						// width: 20%;
						flex: 1;
					}
					&:nth-child(5) {
						width: 18%;
					}
				}

				.action {
					display: flex;
					align-items: center;
					justify-content: space-between;
					white-space: nowrap;
					.btn {
						cursor: pointer;
						color: #0B5CFF;
						&:last-child {
							margin-bottom: 0;
						}
					}
				}
			}
		}
	}
</style>